import React from 'react'
import ReactPlayer from 'react-player/lazy'

export class ResponsivePlayer extends React.Component {
  constructor(props) {
    super(props)

    this.home = this.props.home

    this.state = {
      playing: this.home,
    }
  }

  render() {
    const video = this.props.url

    return (
      <>
        <ReactPlayer
          ref="player"
          className="react-player"
          url={video}
          width="100%"
          height="100%"
          playing={true}
          playsinline={this.state.playing}
          loop={this.state.playing}
          muted={this.state.playing}
          controls={!this.state.playing ? true : false}
          light={!this.state.playing ? true : false}
          // onPlay={() => {
          //   this.refs.player.wrapper.parentElement.nextSibling
          //     ? this.refs.player.wrapper.parentElement.nextSibling.classList.add(
          //         '-hidden'
          //       )
          //     : ''
          // }}
          // onPause={() => {
          //   this.refs.player.wrapper.parentElement.nextSibling
          //     ? this.refs.player.wrapper.parentElement.nextSibling.classList.remove(
          //         '-hidden'
          //       )
          //     : ''
          // }}
        />
      </>
    )
  }
}
