import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import SEO from '../components/SEO/SEO'
import website from '../../config/website'
import { use100vh } from 'react-div-100vh'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { ResponsivePlayer } from '../components/ResponsiveVideoHero'

import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Pagination, Autoplay])
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

import { gsap } from 'gsap'

export const HomepageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const topMenu = data.prismicTopMenu || {}
  const workSlides = homepage.data.work_slide || {}

  const { type, url } = homepage || {}
  const activeDoc = {
    type,
    url,
  }

  const height = use100vh()
  const browserHeight = height ? height - 70 : '100vh - 70px'

  const paginationRef = React.useRef(null)

  // useEffect(() => {
  //   console.log('html')
  //   document.querySelector('html').classList.add('home-page')
  // }, [])

  const renderedWorkSlides = workSlides
    ? workSlides.map((work, index) => (
        <SwiperSlide key={`slide-${index}`}>
          <div className="home-page__slide typ">
            <div className="home-page__media">
              <div className="home-page__spinner">
                <div className="home-page__spinner-icon"></div>
              </div>
              {work.video ? (
                <ResponsivePlayer url={work.video.embed_url} home={true} />
              ) : (
                <img
                  className="home-page__img lazyload"
                  alt={work.image ? work.image.alt : ''}
                  data-src={work.image ? work.image.fluid.src : ''}
                  data-srcset={work.image ? work.image.fluid.srcSet : ''}
                />
              )}
            </div>
            <div className="home-page__content">
              <h2 className="h1">{work.title.text}</h2>
              <AniLink
                cover
                direction="right"
                bg="#000000"
                duration={1.5}
                to={work.work_slide_page_link.url}>
                View case study
              </AniLink>
            </div>
          </div>
        </SwiperSlide>
      ))
    : null

  return (
    <>
      <SEO
        title={`${homepage.data.display_title.text} | ${website.titleAlt}`}
        pathname={typeof window === 'undefined' ? '' : location.pathname}
        desc={homepage.data.social_description_override || data.description}
        banner={homepage.data.social_image_override.url}
        node={homepage}
      />
      <section>
        <div
          className="module homepage-carousel"
          style={{ height: browserHeight }}>
          <Swiper
            allowTouchMove={false}
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{
              delay: 7000,
            }}
            loop={true}
            watchOverflow={true}
            pagination={{
              el: paginationRef.current,
              type: 'bullets',
              clickable: true,
            }}
            onSlideChange={(swiper) => {
              const activeSlide = swiper.slides[swiper.activeIndex]
              const activeSlideContent = activeSlide.querySelector(
                '.home-page__content'
              ).children
              gsap.fromTo(
                activeSlideContent,
                { autoAlpha: 0, y: 40 },
                {
                  autoAlpha: 1,
                  y: 0,
                  stagger: 0.1,
                  delay: 0.3,
                  duration: 1,
                  ease: 'power4.out',
                }
              )
            }}
            onSwiper={(swiper) => console.log(swiper)}
            onBeforeInit={(swiper) =>
              (swiper.params.pagination.el = paginationRef.current)
            }>
            {renderedWorkSlides}
            <div ref={paginationRef}></div>
          </Swiper>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  query homepageQuery {
    prismicHomepage {
      data {
        display_title {
          text
        }
        social_description_override
        social_image_override {
          url
        }
        work_slide {
          work_slide_page_link {
            url
          }
          title {
            html
            raw
            text
          }
          video {
            embed_url
          }
          image {
            alt
            fluid(imgixParams: { fit: "crop" }) {
              src
              srcSet
            }
          }
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
  }
`

export default withPreview(HomepageTemplate)
